[quill-editor-toolbar] .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}

[quill-editor-toolbar] .ql-font span[data-label="Arial"]::before {
  font-family: Arial;
}

[quill-editor-toolbar] .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}

[quill-editor-toolbar] .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}

/* Set content font-families */

.ql-font-mirza {
  font-family: "Mirza";
}

.ql-font-aref {
  font-family: "Aref Ruqaa";
}

.ql-font-roboto {
  font-family: "Roboto";
}

.ql-font-arial {
  font-family: Arial;
}