@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/nova/theme.css";
//changed was nova light
@import "../node_modules/primeng/resources/primeng.min.css";

@import "./app/theme/variable.scss";
@import "quill-fonts";

//fonts
@font-face {
  font-family: SF UI DISPLAY BLACK;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-black-58646a6b80d5a.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY SANSBOLD;
  src: url("assets/fonts/opensans/OpenSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY SANS-SEMI-BOLD;
  src: url("assets/fonts/opensans/OpenSans-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY BLD;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-bold-58646a511e3d9.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY HEAVY;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-heavy-586470160b9e5.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY LIGHT;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-light-58646b33e0551.otf") format("opentype");
}

@font-face {
  font-family: "SF UI DISPLAY MEDIUM";
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-medium-58646be638f96.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY SEMIBOLD;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-semibold-58646eddcae92.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY THIN;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-thin-58646e9b26e8b.otf") format("opentype");
}

@font-face {
  font-family: SF UI DISPLAY ULTRALIGHT;
  src: url("assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-ultralight-58646b19bf205.otf") format("opentype");
}

.sansbold {
  font-family: SF UI DISPLAY SANSBOLD;
}

@font-face {
  font-family: SF UI DISPLAY RGLR;
  src: url("assets/fonts/sf-ui-display-cufonfonts/SF-UI-Display-Regular.ttf") format("opentype");
}

@font-face {
  font-family: RUBIC MEDIUM;
  src: url("assets/fonts/Rubik/Rubik-Medium.ttf") format("opentype");
}

@font-face {
  font-family: RUBIC BOLD;
  src: url("assets/fonts/Rubik/Rubik-Bold.ttf") format("opentype");
}

html {
  height: 100vh;
}

body {
  height: 100%;
}

app-root {
  display: block;
  height: 100%;
}

:host {
  height: 100%;
}

*:focus {
  outline: 0;
  outline: none;
}

.w-33 {
  width: 33%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-18 {
  width: 18%;
}

.w-60 {
  width: 60%;
}

* {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(195, 193, 193);
    border-radius: 25px;
    transition: 0.5s ease-in;
  }
}

/** @Overidding prime ng
*
*/
.p-menubar .p-menubar-custom {
  padding: 0 !important;
}

/** **/
.p-card:hover {
  // cursor: pointer;
  border: 2px solid;
  border-color: var(--red-hover) !important;
}

.cus-cal-border {
  border: 1px solid;
  border-color: var(--grey-border) !important;
  width: 100%;
}

.dialog-preview-width {
  width: 60vw !important;
  min-width: 60vw !important;
}

.p-card-content {
  font-size: 13px;
  // text-align: center;
  // overflow: auto;
  color: var(--grey-shade);
  font-family: "SF UI DISPLAY RGLR";
}

.p-card-header {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  background: #2d4763 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.black {
  font-family: SF UI DISPLAY BLACK;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: var(--dot-grey) !important;
}

.dot:hover {
  background: var(--black) !important;
}

.spacing {
  margin-bottom: 3px;
}

.tbold {
  font-family: SF UI DISPLAY BLD;
}

.paginotorScroll {
  color: var(--black) !important;
  text-decoration: none;
}

.theavy {
  font-family: SF UI DISPLAY HEAVY;
}

.sbmt-btn {
  font-weight: 600;
}

.tlight {
  font-family: SF UI DISPLAY RGLR;
}

//
.tmedium {
  font-family: SF UI DISPLAY MEDIUM;
}

textarea::-webkit-input-placeholder {
  opacity: 0.5;
}

.tsemibold {
  font-family: SF UI DISPLAY SEMIBOLD;
}

.tthin {
  font-family: SF UI DISPLAY THIN;
}

.tultralight {
  font-family: SF UI DISPLAY ULTRALIGHT;
}

label {
  color: var(--label-blue-dark) !important;
}

/*specific font sizes*/

.courier-family //courier family
{
  font-family: SF UI Display;
}

.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.ezy-bold {
  font-weight: 900;
  font-weight: bold;
}

// .p-button:enabled:hover {
// color: var(--white) !important;
// background-color: var(--red-content) !important;
// }

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.pro-bold {
  font-weight: small;
}

.cursor-pointer {
  cursor: pointer;
}

// body .p-tree {
//   height: 151px;
// }
// .p-card-body {
//   overflow-x: hidden;
//   overflow-y: hidden;
// }
.hidden {
  visibility: hidden;
}

.p-card-body {
  padding: 0.1rem 1rem !important;
}

header.d-flex {
  width: 100%;
  background-color: var(--white) !important;
  height: 50px;
}

.side-menu-collapsed .navbar-header .navbar-brand img {
  width: 35px;
}

.side-menu-collapsed .navbar-header {
  padding-left: 0px !important;
  justify-content: center;
}

.header-dropdown {
  margin-left: -157px;
  margin-top: 48px;
  background-color: var(--white) !important;
  border-radius: 10px;
  padding: 0;
  box-shadow: 0px 5px 11px var(--black-shadow) !important;
  opacity: 1;
}

.card-header {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding: 1.5rem 1rem !important;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  color: var(--white) !important;
  background-color: var(--red-content) !important;
}

ul.p-dropdown-items.p-dropdown-list.p-component.p-reset {
  font-family: SF UI DISPLAY RGLR, serif;
}

p-dropdown {
  z-index: 1000;
}

.dropdown-arrow {
  position: fixed;
}

.drop-search {
  body .p-dropdown {
    border: 1px solid !important;
    border-color: var(--grey-border-7) !important;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: var(--black-text-2) !important;
  text-align: left;
  list-style: none;
  background-color: var(--white) !important;
  background-clip: padding-box;
  border: 1px solid;
  border-color: var(--grey-background-rgb-3) !important;
  border-radius: 0.25rem;
}

.no-padding {
  padding: 0 !important;
}

// .no-paddingtb {
// padding-top: 0 !important;
// padding-bottom: 0 !important;
// }
.no-margin-f {
  margin: 0 !important;
}

.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

body .p-disabled,
body .p-component:disabled {
  cursor: not-allowed !important;
}

.card-body {
  background: var(--blue-dark-2) !important;
}

// paginator styles
body .p-paginator {
  background-color: var(--white) !important;
  border: none !important;
  font-family: SF UI DISPLAY SANS-SEMI-BOLD !important;
  font-size: 11px !important;
  margin: 0 0 0 3rem;
}

.p-dialog .p-dialog-header {
  border-bottom: none !important;
  background: #ffffff !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #848484 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  color: #848484 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--red-btn) !important;
  color: var(--white) !important;
  border-radius: 3px;
  padding: 5px !important;
}

// .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
//   background: #e91224;
//   border-color: #007ad9;
//   color: #fff;
// }

body .p-dropdown {
  border: 1px solid !important;
  border-color: var(--grey-border) !important;
}

// style for rows per page section
body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
  color: var(--grey-border-3) !important;
  font-size: 10px !important;
  font-family: SF UI DISPLAY SANS-SEMI-BOLD !important;
  top: 13px !important;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 1em !important;
  position: absolute !important;
  top: 30% !important;
  right: 5% !important;
}

body .p-paginator {
  width: max-content !important;
}

.p-paginator .p-dropdown {
  height: 25px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 1.6rem !important;
  height: 1.6rem !important;
  font-size: 12px !important;
}

.overlay {
  background-color: #efefef;
  position: fixed;
  width: calc(100% - 280px);
  height: calc(100% - 200px);
  z-index: 1000;
  top: 143px;
  left: 280px;
  opacity: 0.5; /* in FireFox */
  filter: alpha(opacity=50); /* in IE */
}

.p-dropdown .p-dropdown-trigger {
  height: 0 !important;
  top: 21px !important;
  right: 4px !important;
}

// paginator styles end

.p-dropdown-label-container {
  width: 100% !important;
  // border: 1px solid var(--grey-border) !important;
  border-radius: 8px;
  height: 38px;
}

// ===================================================
// Styles for PrimeNG--Dropdown
// ===================================================

// .p-dropdown{
//  // width: 9rem !important;
// }
p-dropdown.drop-loc {
  .p-dropdown .p-dropdown-panel {
    top: 0px !important;
  }

  .p-dropdown-filter-container {
    padding: 0px !important;
  }
}

.drop-loc {
  // .p-dropdown-panel.p-component {
  //   top: 48px !important;
  // }
  .p-dropdown {
    display: inline-block; // position: relative;
    cursor: pointer;
    vertical-align: middle; // min-width: 12.5em;
    border: 0px !important;
    width: 100% !important; // background: #f3f3f3;
  }

  // .p-disabled{
  //   cursor:not-allowed !important;
  // }
  .p-dropdown.p-disabled .p-dropdown-trigger,
  .p-dropdown.p-disabled .p-dropdown-label {
    cursor: not-allowed !important;
  }

  //  .p-dropdown.p-disabled .p-dropdown-label .p-dropdown.p-disabled .p-dropdown-trigger{
  //   cursor:not-allowed !important;
  // }

  .p-autocomplete {
    box-shadow: 0 !important;
  }

  .p-autocomplete:focus {
    outline-color: transparent !important;
    outline-style: none !important;
  }

  .p-inputtext {
    font-size: 13px;
    font-family: SF UI DISPLAY MEDIUM;
    color: var(--blue-dark-text) !important;
    padding-left: 20px !important;
    padding-top: 9px !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }

  .p-dropdown .p-dropdown-trigger {
    background-color: var(--white-background) !important;
    border-bottom: 1px solid #a6a6a6 !important;
  }

  .p-dropdown label.p-dropdown-label {
    //  cursor: pointer;
    height: 39px !important;
    width: 100% !important; //199 replaced with 210
  }

  .p-dropdown-label.p-placeholder {
    font-family: SF UI Display MEDIUM !important;
    font-size: 13px !important;
    color: var(--blue-dark-text) !important;
    opacity: 0.4;
    padding-left: 20px !important;
    padding-top: 9px !important;
  }
}

.drop-loc {
  ul.p-dropdown-items.p-dropdown-list.p-component.p-reset {
    font-family: SF UI DISPLAY MEDIUM !important;
    font-size: 13px !important;
  }
}

// .drop-icon-arrow {
// .pi-chevron-down:before {
//   content: "\e913" !important;
// }
// }

.side-menu-left-wrapper .panel {
  background-color: transparent;
  color: var(--grey-background-rgb-4) !important;
  cursor: pointer;
}

.side-menu-left-wrapper {
  background-color: var(--blue-dark-2) !important;
  min-height: 100vh;
  font-size: 10px;
  position: fixed;
}

.side-menu-left-label {
  margin-left: 15px;
  font-size: 11px;
}

.side-menu-left-item .card-body {
  padding: 0px !important;
}

.show {
  display: block;
}

// .hide {
//   display: none;
// }
.side-menu-left-item.active {
  color: var(--white) !important;
}

.side-menu-left-item.active:hover {
  background-color: var(--grey-background-rgb-hover) !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.link-active .btn-link,
.link-active .material-icons,
.link-active .side-menu-left-label,
.link-active .panel-group .panel-title:before {
  color: var(--blue-dark-text) !important;
  opacity: 100% !important;
}

.active .btn-link,
.active .material-icons,
.active .side-menu-left-label,
.active .panel-group .panel-title:before {
  color: var(--grey-title) !important;
}

//sidebar styles
accordion-group.side-menu-left-item.side-menu-item-single.panel.link-active {
  .panel-enabled {
    border-right: 5px solid !important;
    border-color: var(--red-content) !important;
  }

  outline: none !important;
  background: var(--white) !important;
  // font-family: SF UI DISPLAY BLD;
}

accordion-group.side-menu-left-item.side-menu-item-single.panel.active {
  .panel-enabled {
    border-right: 5px solid !important;
    border-color: $red-content !important;
  }

  outline: none !important;
  background: var(--blue-dark-3) !important;
}

//side bar fonts changed
accordion.side-menu-left.panel-group {
  font-family: SF UI DISPLAY RGLR;
  line-height: 0px;
}

//sidebar red border when clicking removed
accordion-group.side-menu-left-item.side-menu-item-single.panel {
  outline: none !important;
}

.side-menu-collapsed .material-icons {
  font-size: 12px;
}

.side-menu-collapsed-list li .material-icons {
  color: var(--grey-background-rgb-7) !important;
}

.ng-tns-c4-0.ng-trigger.ng-trigger-panelState.p-sidebar.p-component.p-sidebar-active.p-sidebar-left {
  z-index: 1 !important;
}

.w-100 {
  width: 100% !important;
}

.w-65 {
  width: 65% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.layerone {
  // position: relative;
  box-shadow: 0px 0px 1px grey;
  z-index: 0;
}

.layertwo {
  position: relative;
  z-index: 0;
}

.layerthree {
  position: relative;
  z-index: 0;
}

.layerfour {
  z-index: 5;
}

.header-dropdown {
  margin-left: 18px;
  margin-top: -6px;
  background-color: var(--white) !important;
  border-radius: 10px;
  padding: 0;
  cursor: pointer; // -moz-box-shadow: 0px 0px 4px 1px rgb(151, 148, 148);
  //  -webkit-box-shadow: 0px 0px 4px 1px rgb(151, 148, 148);
  //  box-shadow: 0px 0px 4px 1px rgb(151, 148, 148);
}

//cursor pointer not working issue style
.point:hover {
  cursor: pointer;
}

// padding classes
.pl-12 {
  padding-left: 12rem !important;
}

.pt-8r {
  padding-top: 8rem !important;
}

// .breadcrumb-width {
//   width: 1070px;
//   float: left;
// }

body .p-breadcrumb {
  width: 100%;
  border: none !important;
}

//.p-calendar {
// width: 100% !important;
//}

.calender-style {
  width: 100% !important;
}

.calender-area-style {
  width: 100% !important;
  border: 1px solid;
  border-color: var(--grey-border) !important;
  color: var(--blue-dark-text) !important;
  font-size: 13px !important;
  font-family: SF UI DISPLAY MEDIUM !important;
  height: 38px !important;
  padding-left: 20px !important;
}

.p-calendar .p-datepicker {
  min-width: 0 !important;
  padding: 0 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}

.well-align {
  .ng-trigger.ng-trigger-overlayAnimation.ng-tns-c11-3.p-datepicker.p-component.ng-star-inserted {
    top: 34.016px !important;
  }

  .p-dropdown .p-dropdown-trigger {
    height: 0 !important;
    top: 22px !important;
  }
}

.sidebar-width {
  width: 283px;
  overflow-x: hidden !important;
}

.p-dropdown label.p-dropdown-label {
  cursor: pointer;
  font-size: 13px !important;
  font-family: SF UI DISPLAY MEDIUM !important;
}

// body .p-datepicker table td > a,
// body .p-datepicker table td > span {
//  // background-color: var(--red-btn) !important;
// }
.p-datepicker table td > span.p-highlight {
  background-color: var(--red-btn) !important;
}

body .p-datepicker table {
  font-size: 12px !important;
}

//checkbox styles
span.p-chkbox-icon.p-clickable.pi.pi-check {
  background-color: #082857 !important;
  color: var(--white) !important;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 0px !important;
  font-size: 100%;
}

body .p-chkbox .p-chkbox-box {
  border-color: var(--white) !important;
}

.p-chkbox-box.p-component {
  width: 12px;
  height: 12px;
  border-radius: 0px !important;
}

body .p-chkbox .p-chkbox-box.p-focus {
  box-shadow: none !important;
}

/*** Checkbox ***/
p-checkbox {
  .p-chkbox-label {
    font-size: 11px;
    font-family: SF UI DISPLAY RGLR;
    color: var(--blue-dark-text) !important;
    margin: 0px !important;
    padding-bottom: 4px;
    opacity: 50%;
  }
}

//style of chips
ul.p-inputt.p-inputext {
  background: var(--white-background) !important;
  height: 38px;
  width: 440px !important;
  border: 0px !important; // font-family: SF UI Display !important;
  // padding: 0px 20px;
  color: var(--grey-shade) !important;
  font-size: 15px;
}

//submit button styles
button.cursor-pointer.btn-red.px-2.py-1.p-button.p-component.p-button-text-empty {
  padding-right: 20px !important;
}

.container-fluid {
  padding-left: 0px !important;
}

// .p-breadcrumb.p-component {
//   position: fixed; // z-index: 100;
//   left: 220px;
//   right: -1px;
// }

// font color of breadcrumb
span.p-menuitem-text {
  color: white !important;
  font-family: SF UI DISPLAY MEDIUM;
  font-size: 13px;
}

//calender styles
input.ng-tns-c7-3.p-inputtext.p-component.ng-star-inserted {
  background-color: var(--white-background) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 32px;
}

btn-align input.ng-tns-c7-4.p-inputtext.p-component.ng-star-inserted {
  background-color: var(--white-background) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 32px;
}

input.ng-tns-c7-5.p-inputtext.p-component.ng-star-inserted {
  background-color: var(--white-background) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

input.ng-tns-c7-6.p-inputtext.p-component.ng-star-inserted {
  background-color: var(--white-background) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

input.ng-tns-c7-11.p-inputtext.p-component.ng-star-inserted {
  background-color: var(--white-background) !important;
  border-top: none;
  border-left: none;
  border-right: none;
}

// delete confirmation box styles
span.p-button-icon-left.p-clickable.pi.pi-times {
  display: none;
}

span.p-button-icon-left.p-clickable.pi.pi-check {
  display: none;
}

button.undefined.p-button.p-component.p-button-text-icon-left.ng-star-inserted {
  width: 57px;
  height: 28px;
  background-color: var(--white) !important;
  border: 0px !important;
  border-radius: 53px;
  outline: none !important;
  color: var(--red-content) !important;
  box-shadow: none;
}

span.p-button-text.p-clickable {
  display: block;
  position: absolute;
  top: 2px;
  right: 6px;
  text-transform: capitalize;
  font-family: SF UI DISPLAY RGLR;
}

button:focus {
  outline: 0 !important;
}

body .p-dialog .p-dialog-footer {
  position: static !important;
}

// removing the border of dialog footer
body .p-dialog .p-dialog-footer {
  // border: none !important;
  border: 1px solid;
  border-color: var(--white) !important;
  background-color: var(--white) !important;
}

//delete dialog footer styles
body .p-dialog .p-dialog-footer button {
  width: auto;
}

body .p-dialog .p-dialog-footer {
  color: var(--white) !important;
}

body .p-dialog .p-dialog-footer button {
  font-family: SF UI DISPLAY MEDIUM;
}

// //remove the border of dialogbox
body .p-dialog .p-dialog-content {
  background-color: var(--white) !important; // color: #191919;
  border-color: var(--white) !important; // padding: 0.571em 1em;
}

.p-dialog .p-dialog-content {
  padding: 0 0 0 1rem !important;
}

.image-display .p-dialog .p-dialog-content {
  height: 300px !important;
  background-color: var(--white) !important; // color: #191919;
  border-color: var(--white) !important; // padding: 0.571em 1em;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
  background: #96949430 0% 0% no-repeat padding-box !important;
}

.modal-dialog {
  margin: 0 !important;
}

.p-confirm-btn .p-dialog-content {
  text-align: center; // padding-top: 0px !important;
  font-family: SF UI DISPLAY RGLR;
}

//dialogbox header styles
body .p-dialog .p-dialog-titlebar .p-dialog-title {
  margin: 0;
  float: none;
  padding-left: 18px;
  font-size: 16px;
  color: var(--blue-dark-text) !important;
}

// // setting the background of dialog box title bar as white
body .p-dialog .p-dialog-titlebar {
  background-color: var(--white) !important;
}

.p-dialog .p-dialog-titlebar {
  position: relative;
  padding-top: 30px;
  border: 0;
}

// multiselect styles

.image-search-mutiselect {
  .p-multiselect {
    background: #ffffff;
    border: 1px solid;
    border-color: var(--grey-border-7) !important;
    font-size: 13px;
    font-family: SF UI DISPLAY MEDIUM;
    height: 38px;
    border-radius: 4px !important;
    padding-left: 12px;
  }
}

body .p-multiselect {
  background: #ffffff;
  border: 1px solid;
  border-color: var(--grey-border) !important;
  font-size: 13px;
  font-family: SF UI DISPLAY MEDIUM;
  height: 38px;
  border-radius: 8px;
  padding-left: 12px;
}

.p-multiselect .p-multiselect-trigger {
  right: 3px;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  font-size: 1.1em !important;
}

body .p-multiselect .p-multiselect-label {
  padding-top: 9px !important;
  color: var(--blue-dark-text) !important;
}

body .p-multiselect-panel .p-multiselect-header .p-chkbox {
  padding-top: 4px;
}

body
.p-multiselect-panel
.p-multiselect-header
.p-multiselect-filter-container
.p-inputtext {
  padding: 0 !important;
  padding-right: 2em;
}

.p-multiselect-header .p-multiselect-close {
  display: none !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-chkbox {
  padding-top: 5px;
}

body .p-multiselect-panel .p-multiselect-items {
  padding: 0;
  font-size: 12px;
  font-family: SF UI DISPLAY MEDIUM;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--grey-background) !important;
  color: var(--blue-dark-text) !important;
}

// multiselect styles end

//changing the background of dialog box
body .p-component-overlay {
  background-color: var(--grey-background-rgb-6) !important;
}

.p-confirm-btn button:first-child {
  float: right;
  background-color: var(--red-btn) !important;
  border: 0px !important;
  color: var(--white) !important;
  font-family: SF UI DISPLAY RGLR;
}

.p-confirm-btn span.p-button-text.p-clickable {
  display: block;
}

//dropdown styles

.p-dropdown .highlightText,
.p-multiselect .highlightText {
  color: black;
  font-weight: bold;
  background-color: rgb(248, 248, 115);
  border-radius: 5px;
}

content.p-component.p-reset {
  font-size: 11px;
}

ul.p-dropdown-items.p-dropdown-list.p-component.p-reset {
  font-family: SF UI DISPLAY RGLR;
}

// alternate table row color
body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--white) !important;
}

.table-allign {
  .p-datatable .p-datatable-tbody > tr:hover {
    background-color: var(--grey-background) !important;
    cursor: -webkit-grab;
  }
}

.tag-table-allign {
  .p-datatable .p-datatable-tbody > tr:hover {
    background-color: var(--grey-background) !important;
    // cursor: -webkit-grab;
  }
}

body .p-datatable .p-datatable-thead > tr > th {
  // font-weight: 400;
  background-color: #dbe3ed;
  padding: 1rem;
  font-size: 12px;
}

.user-comp .p-datatable .p-datatable-thead > tr > th {
  background-color: #dbe3ed;
  padding: 1rem;
  font-size: 12px;
}

.management-table .p-datatable .p-datatable-thead > tr > th {
  background-color: var(--grey-border) !important;
  padding: 1rem;
  font-size: 12px;
}

body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}

//scrollbar starts
div::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

div::-webkit-scrollbar-thumb {
  background: var(--grey-scroll-thumb) !important;
  border-radius: 17px;
}

div::-webkit-scrollbar-track {
  background: var(--grey-scroll-track) !important;
}

// fam card tree selection
body
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content
.p-treenode-label.p-highlight {
  background-color: var(--white) !important;
  color: var(--black) !important;
  cursor: default !important;
}

body
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content.p-treenode-selectable
.p-treenode-label:not(.p-highlight):hover {
  cursor: default !important;
}

body
.p-tree
.p-tree-container
.p-treenode
.p-treenode-content
.p-treenode-icon {
  cursor: default !important;
}

// end fam card tree selection

// ===================================================
// Styles for p-dialog
// ===================================================

p-dialog {
  .p-dialog {
    box-shadow: 0px 22px 49px var(--black-shadow-2) !important;
  }

  .p-dialog-titlebar {
    border: 0 !important;
    // border-top-left-radius: 7px !important;
    // border-top-right-radius: 7px !important;
  }

  .p-dialog-footer {
    border: 0 !important;
    border-top: 1px solid !important;
    border-color: var(--grey-footer-border) !important;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }

  .batch-listing {
    height: 35vh;
    overflow-y: auto;

    input.cus-input {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

.dialog-style-email {
  width: 840px !important;
  height: 600px !important;
}

.dialog-style {
  width: 340px !important;
  height: 600px !important;
}

.dialog-style1 {
  width: 500px !important;
}

.dialog-style2 {
  width: 330px !important;
}

.dialog-style-delete {
  width: 485px !important;
}

.custom-label {
  color: var(--grey-shade) !important;
  opacity: 50%;
}

.disabled {
  cursor: not-allowed;
}

.tooltip-cursor {
  cursor: default !important;
}

.p-tooltip {
  max-width: 400px;
  white-space: pre-wrap;
  pointer-events: none;
}

.btn:disabled {
  opacity: 0.2 !important;
  cursor: not-allowed !important;
}

//styles of input field
input.cus-input {
  height: 40px !important; // width: 440px ;
  padding-left: 20px !important;
  border: 1px solid var(--grey-border) !important; // padding: 0px 20px;
  color: var(--blue-dark-text) !important;
  font-size: 13px !important;
  font-family: SF UI DISPLAY MEDIUM !important;
}

input.cus-input::placeholder {
  opacity: 0.5 !important;
}

textarea.cus-input {
  background: var(--white) !important;
  height: 90px; // width: 440px !important;
  padding: 8px 20px;
  color: var(--blue-dark-text) !important;
  font-size: 12px;
  border: 1px solid;
  border-color: var(--grey-border) !important;
  border-radius: 4px;
  font-family: SF UI DISPLAY MEDIUM;
}

// body .p-inputtext {
//   padding: 8px 20px;
//   color: var(--blue-dark-text) !important;
//   font-size: 12px;
//   border: 1px solid var(--grey-border) !important;
//   border-radius: 4px;
//   font-family: SF UI DISPLAY MEDIUM;
// }

// body .p-inputtext {
//   background: var(--white) !important;
//   height: 90px; // width: 440px !important;
//   padding: 8px 20px;
//   color: var(--blue-dark-text) !important;
//   font-size: 12px;
//   border: 1px solid var(--grey-border) !important;
//   border-radius: 4px;
//   font-family: SF UI DISPLAY MEDIUM;
// }

//remove outline border of textbox
textarea.cus-input {
  outline: none;
  resize: none;
}

//remove border of input box after click
input.cus-input {
  outline: none;
}

// arrange the size of button
span.p-button-text.p-clickable {
  display: none;
}

//styles for cancel button
.btn-white {
  background-color: var(--white) !important;
  color: var(--red-btn) !important;
  border-radius: 8px !important;
  border: none !important;
  font-family: SF UI Display RGLR;
  font-size: 13px;
  padding: 0px 10px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.btn-white:hover {
  border: 1px solid;
  border-color: var(--red-btn) !important;
  background-color: var(--white) !important;
  color: var(--red-btn) !important;
}

body .p-sidebar {
  width: 280px;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: 4px 0px 6px 0 var(--grey-background-rgb-5) !important;
  z-index: unset !important;
}

//.p-button:enabled:hover {
// color: var(--white-btn-text) !important;
// background-color: var(--red-content) !important;
//}
.btn-red {
  background-color: var(--red-btn) !important;
  border: none !important;
  border: 1px solid;
  border-color: var(--red-btn) !important;
  border-radius: 8px !important; // margin-right: 15px !important;
  font-family: SF UI DISPLAY RGLR;
  font-size: 13px;
  box-shadow: 0px 22px 49px var(--black-shadow-2) !important;
  color: var(--white) !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.btn-red:disabled {
  background: var(--red-btn-disabled) !important;
  border: 1px solid;
  border-color: var(--red-btn-disabled) !important;
}

.not-allowed {
  cursor: not-allowed;
}

//FONT FAMILY OF TABLE BODY
tbody.p-datatable-tbody {
  font-family: SF UI DISPLAY RGLR;
  font-size: 12px;
}

.p-breadcrumb ul li:last-child span {
  // text-indent: 20px;
  color: var(--blue-dark-text) !important;
  font-size: 13px;
  font-family: SF UI DISPLAY RGLR;
  // opacity: .5;
  // text-align-last: right;
}

// span.p-menuitem-text {
// }

@media (min-width: 0) {
  /**
sidebar styles Begin
*/
  span.side-menu-left-label {
    display: none;
  }
  div#expanded-navbar {
    top: 48px;
    width: 56px;
  }

  .set-row-width {
    width: 100% !important;
  }
  /**
sidebar styles End
*/

  /**
header styles Begin
*/
  .header {
    position: fixed;
    top: 0;
    overflow: hidden;
    padding: 2px 20px;
    display: flex;
    height: auto;
    flex-direction: row;
    font-family: "Open Sans", sans-serif; // border-bottom: 1px rgb(231, 228, 228) solid;
  }
  .header.d-flex {
    width: 100%;
  }
  .img-size {
    // margin-left: -23px;
    width: 50%;
    // margin-top: -19px;
  }
  .font-group {
    font-family: "Open Sans", sans-serif;
    font-size: 51%;
    padding-bottom: 20px;
  }
  /**
header styles Ends
*/
  /**
Breadcrumb style Begin
*/
  // .p-breadcrumb.p-component {
  //   top: 50px; //48 replaced
  //   position: fixed;
  //   left: 56px;
  //   right: -1px;
  // }

  span.p-menuitem-text {
    font-size: 8px;
  }

  /**
Breadcrumb style Begin
*/
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 6rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 10px;
    color: var(--black-text-2) !important;
    text-align: left;
    list-style: none;
    background-color: var(--white) !important;
    background-clip: padding-box;
    border: 1px solid;
    border-color: var(--grey-background-rgb-3) !important;
    border-radius: 0.25rem;
  }

  .set-row-width {
    width: 100% !important;
  }

  /**
sidebar styles Begin
*/
  span.side-menu-left-label {
    display: none;
  }

  div#expanded-navbar {
    width: 56px;
  }
  /**
sidebar styles End
*/

  /**
header styles Begin
*/
  .header {
    position: fixed;
    overflow: hidden;
    padding: 2px 20px;
    display: flex;
    height: auto;
    flex-direction: row;
    font-family: "Open Sans", sans-serif; // border-bottom: 1px rgb(231, 228, 228) solid;
  }
  .header.d-flex {
    // width: 78%;
    width: 100%;
  }
  .font-group {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding-bottom: 20px;
  }
  /**
header styles Ends
*/
  /**
Breadcrumb style Begin
*/
  span.p-menuitem-text {
    font-size: 8px;
  }
  /**
Breadcrumb style Ends
*/

  .sidebar-allign {
    position: fixed;
    width: 36%;
    top: 138px;
  }

  .pl-16 {
    padding-left: 6rem !important;
  }

  .pt-8r {
    padding-top: 8rem !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 7rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: var(--black-text-2) !important;
    text-align: left;
    list-style: none;
    background-color: var(--white) !important;
    background-clip: padding-box;
    border: 1px solid;
    border-color: var(--grey-background-rgb-3) !important;
    border-radius: 0.25rem;
  }

  .header-dropdown {
    margin-left: 0px;
    margin-top: -6px;
    padding: 0;
  }
  .login-item {
    width: 20%;
  }

  /**
sidebar styles Begin
*/
  span.side-menu-left-label {
    display: none;
  }
  div#expanded-navbar {
    width: 56px;
  }
  /**
sidebar styles End
*/

  /**
header styles Begin
*/
  .header {
    position: fixed;
    overflow: hidden;
    padding: 2px 20px;
    display: flex;
    height: auto;
    flex-direction: row;
    font-family: "Open Sans", sans-serif; // border-bottom: 1px rgb(231, 228, 228) solid;
  }
  .header a {
    padding: 2px;
  }
  .header.d-flex {
    width: 94%;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 7rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: var(--black-text-2) !important;
    text-align: left;
    list-style: none;
    background-color: var(--white) !important;
    background-clip: padding-box;
    border: 1px solid;
    border-color: var(--grey-background-rgb-3) !important;
    border-radius: 0.25rem;
  }
  .font-group {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    padding-bottom: 20px;
    padding-right: 3px;
  }
  /**
header styles Ends
*/
  .sidebar-allign {
    position: fixed;
    width: 25%;
    top: 138px;
  }
  .pl-16 {
    padding-left: 6rem !important;
  }
  .pl-9r {
    padding-left: 3rem !important;
  }

  .pt-8r {
    padding-top: 8rem !important;
  }
  .items-allign {
    padding-top: 3.2rem;
    width: 95vw;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 767px) {
  .header-dropdown-width {
    min-width: 0px !important;
  }
  .set-row-width {
    width: 100% !important;
  }
  /**
Breadcrumb style begins
*/
  // .p-breadcrumb.p-component {
  //   top: 50px;
  //   position: fixed;
  //   left: 220px;
  //   right: -1px;
  // }
  /**
Breadcrumb style Ends
*/
  /**
sidebar styles Begin
*/
  div#expanded-navbar {
    top: 60px;
    width: 100%;
  }
  .side-menu-left-label {
    margin-left: 17px;
    font-size: 11px;
  }
  span.side-menu-left-label {
    display: unset;
  }
  span.p-menuitem-text {
    font-size: 13px;
  }
  // .avoid-sidebar-overlap {
  //   margin-left: 220px;
  // }
  /**
sidebar styles End
*/
  /**
header styles Begin
*/
  .header {
    position: fixed;
    overflow: hidden;
    padding: 2px 20px;
    display: flex;
    height: auto;
    flex-direction: row;
    font-family: "Open Sans", sans-serif; // border-bottom: 1px rgb(231, 228, 228) solid;
  }
  .header.d-flex {
    width: 100%;
  }
  // .img-size {
  //   margin-left: -4px;
  //   width: 39%;
  //   margin-top: 10px;
  // }

  .img-size {
    margin-left: 10px;
    width: 48%;
    margin-top: 2px;
  }

  .font-group {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding-bottom: 20px;
    padding-right: 5px;
  }
  /**
header styles Ends
*/
  .sidebar-allign {
    position: fixed;
    width: 18%;
    top: 58px;
  }

  .pl-16 {
    padding-left: 17rem !important;
  }
  .pl-9r {
    padding-left: 12rem !important;
  }

  .pt-8r {
    padding-top: 8rem !important;
  }
  .items-allign {
    padding-top: 3.2rem;
    width: 95vw;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .sidebar-allign {
    position: fixed;
    width: 180px;
    top: 57px;
  }

  .pl-16 {
    padding-left: 17rem !important;
  }

  .pt-8r {
    padding-top: 8rem !important;
  }
  .pl-9r {
    padding-left: 9rem !important;
  }
  .items-allign {
    padding-top: 3.2rem;
    width: 79vw !important;
  }
}

.max-width-98 {
  max-width: 98% !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.main-cards {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 550px;
  max-height: 1000px;
  background-color: red;
  padding-bottom: 5px;
}

.date-select input::placeholder {
  color: #082857 !important;
  opacity: 1 !important;
}

.p-tabview-panels {
  margin-top: 20px !important;
  border: none !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li:focus {
  outline: none !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li {
  font-size: 13px;
  text-transform: uppercase;
  margin-right: -1px;
  margin-left: -1px;
  height: 40px;
}

.p-tabview.p-tabview-top .p-tabview-nav {
  display: flex;
  width: 100%;
  overflow: scroll;
  overflow-y: hidden !important;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent !important;
}

.p-tabview.p-tabview-top .p-tabview-nav::-webkit-scrollbar {
  width: 6px;
  height: 15px;
}

.p-tabview.p-tabview-top .p-tabview-nav::-webkit-scrollbar-thumb {
  background: var(--grey-scroll-thumb) !important;
  border-radius: 17px;
}

.p-tabview.p-tabview-top .p-tabview-nav::-webkit-scrollbar-track {
  top: 100px;
  background: var(--grey-scroll-track) !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li {
  width: auto;
}

.p-tabview.p-tabview-top .p-tabview-nav li:focus,
body .p-tabview.p-tabview-top .p-tabview-nav li:hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:hover,
body .p-tabview.p-tabview-left .p-tabview-nav li:hover,
body .p-tabview.p-tabview-right .p-tabview-nav li:hover {
  box-shadow: none !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li {
  background-color: var(--label-blue-dark) !important;
  color: #ffffff !important;
  border: none !important;
  border-bottom: 7px solid var(--label-blue-dark) !important;
  border-top: 2px solid #29345f !important;
  border-right: 2px solid #29345f !important;
  border-left: 2px solid #29345f !important;
  // border-bottom: 2px solid #29345f !important;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-right: 0 !important;
  border-radius: 0.5rem;
  margin-right: 8px !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li a span {
  color: #ffffff !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li {
  border-radius: 0.5rem;
  margin-right: 8px !important;
  background-color: #e2edfd !important;
  border: none !important;
  border-right: 2px solid #e2edfd !important;
  border-top: 2px solid #e2edfd !important;
  border-left: 2px solid #e2edfd !important;
  border-bottom: 2px solid #e2edfd !important;
}

// sidebar
.side-menu-left-wrapper {
  display: flex !important;
  flex-direction: column !important;
  // justify-content: center !important;
  @media (max-width: 767px) {
    align-items: center !important;
  }
}

.p-inputtext:enabled:focus:not(.p-state-error) {
  box-shadow: none !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li a {
  color: #fff !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li a {
  display: flex !important;
}

// Dashbaord new ui changes
.um-main-table,
.rc-main-table,
.ed-main-table {
  .p-datatable .p-datatable-thead > tr > th {
    background-color: var(--white);
    color: #1c3350;
  }

  .p-datatable table {
    border-collapse: separate;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0 5px;
  }

  .p-datatable .p-datatable-thead > tr > th:first-child {
    min-width: 150px;
  }

  .p-datatable .p-datatable-thead > tr > th:last-child {
    width: 50px;
  }

  .p-datatable .p-datatable-tbody > tr,
  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: var(--white-hover) !important;

    td {
      position: relative;
    }
  }

  .p-datatable .p-datatable-tbody > tr:hover,
  .p-datatable .p-datatable-tbody > tr:nth-child(even):hover {
    background-color: var(--red-hover) !important;

    span {
      position: absolute;
      left: 0;
      height: 100%;
      width: 4px;
      top: 0;
      background: var(--red-secondary);
    }
  }
}

.dropdwon-styles {
  width: 50%;
}

.rc-main,
.ed-main,
.ow-main-list-card-header-icon {
  .p-dropdown .p-component .p-dropdown-clearable {
    height: 1.25rem !important;
  }

  .p-dropdown-label-container {
    height: 1.8125rem;

    .p-dropdown-label {
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
    }
  }

  .p-dropdown .p-dropdown-trigger {
    top: 1rem !important;
  }
}

.ed-main-table {
  .p-datatable .p-datatable-thead > tr > th:nth-child(2) {
    width: 10.625rem;
  }
}

.ml-1,
.mx-1 {
  overflow: hidden !important;
}

///
.p-accordion .p-accordion-header:not(.p-disabled) a .side-span-hover {
  background: transparent !important;
}

.p-accordion
.p-accordion-header:not(.p-disabled).p-highlight
.p-accordion-header-link {
  background-color: var(--red-hover) !important;
  border-color: var(--red-hover) !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .side-span-hover {
  background: var(--red-secondary) !important;
}

.p-accordion
.p-accordion-header:not(.p-highlight):not(.p-disabled)
.p-accordion-header-link {
  background: transparent !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-component:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-accordion:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

///
.cm-main,
.tm-main,
.ow-main {
  .p-accordion .p-accordion-header a:focus {
    box-shadow: none !important;
    text-decoration: none !important;
  }

  .p-accordion .p-accordion-content {
    border: none !important;
    background-color: var(--white-hover);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .p-accordion .p-accordion-header:not(.p-disabled):hover a {
    background-color: var(--red-hover) !important;
    padding: 0 !important;
    text-decoration: none !important;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) a {
    border: none;
    //  background-color: var(--red-hover) !important;
    padding: 0 !important;
    color: var(--black) !important;

    .side-span-hover {
      position: absolute;
      left: 0;
      height: 100%;
      width: 0.25rem;
      top: 0;
      background: var(--red-secondary);
    }
  }

  .p-accordion-toggle-icon {
    display: none;
  }

  .p-accordion-header {
    margin: 0 !important;
    margin-top: 0.1875rem !important;
    border-radius: 0px !important;
    font-family: SF UI DISPLAY MEDIUM;
    font-weight: 400 !important;
    font-size: 0.625rem;
    line-height: 0.75rem;
    height: 1.75rem;

    a {
      padding: 0 !important;
      border-radius: 0px !important;
      display: flex;
      align-items: center;
      height: 100%;
      border: none !important;
      position: relative;
      background-color: var(--white-hover) !important;
    }

    a:hover {
      background-color: var(--red-hover) !important;
      padding: 0 !important;
      margin-top: 0.125rem;

      .side-span-hover {
        position: absolute;
        left: 0;
        height: 100%;
        width: 0.25rem;
        top: 0;
        background: var(--red-secondary) !important;
      }
    }

    .p-accordion {
      border-radius: 0px !important;
    }
  }
}

.p-overlay-content {
  width: inherit !important;
}

.ow-accordion {
  .p-accordion .p-accordion-header a:focus {
    box-shadow: none !important;
  }

  .p-accordion .p-accordion-content {
    border: none !important;
    background-color: var(--white-hover);
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .p-accordion .p-accordion-header:not(.p-disabled):hover a {
    background-color: var(--red-hover) !important;
    padding: 0 !important;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) a {
    border: none;
    background-color: var(--red-hover) !important;
    padding: 0 !important;
    color: var(--black) !important;

    .side-span-hover {
      position: absolute;
      left: 0;
      height: 100%;
      width: 0.25rem;
      top: 0;
      background: var(--red-secondary);
    }
  }

  .p-accordion-toggle-icon {
    display: none;
  }

  .p-accordion-header {
    margin: 0 !important;
    // margin-top: 3px !important;
    border-radius: 0px !important;
    font-family: SF UI DISPLAY MEDIUM;
    font-weight: 400 !important;
    font-size: 0.625rem;
    line-height: 0.75rem;
    height: 1.75rem;

    a {
      padding: 0 !important;
      border-radius: 0px !important;
      display: flex;
      align-items: center;
      height: 100%;
      border: none !important;
      position: relative;
      background-color: var(--white-hover) !important;
    }

    a:hover {
      background-color: var(--red-hover) !important;
      padding: 0 !important;
      margin-top: 0;

      .side-span-hover {
        position: absolute;
        left: 0;
        height: 100%;
        width: 0.25rem;
        top: 0;
        background: var(--red-secondary);
      }
    }

    .p-accordion {
      border-radius: 0px !important;
    }
  }
}

.icon-add {
  margin-top: 0.375rem;
  height: 1.75rem;
  width: 1.75rem;
}

.ed-main-sub-heading {
  .p-dropdown {
    width: 100% !important;
    min-width: 0 !important;
  }
}

@media screen and (max-width: 1250px) {
  .rc-main-heading-sub-inputs-dropdown {
    .p-dropdown {
      width: 100% !important;
    }
  }
}

.p-dropdown-label {
  text-overflow: ellipsis !important;
}

.ng-toggle-switch-button {
  z-index: 0 !important;
}
