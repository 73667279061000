$red-btn: #ec2026;
$red-content: #dd1b3d;
:root {
  //font-colours
  --white: #fff;
  --card-header-white: #f1f1f1;
  --white-background: #f3f3f3;
  --white-btn-text: #fce7eb;
  --white-hover: #f9f9f9;
  --white-hover-2: #f3f4f5;
  --black: #000000;
  --red-btn: #ec2026;
  --red-btn-background: rgb(236, 32, 38, 0.1);
  --red-btn-border: #ec20264d;
  --red-hover: #dd1b49;
  --red-content: #dd1b3d;
  --red-background-light: #f9e4e4;
  --red-btn-disabled: #f97c7f;
  --black-text-2: #212529;
  --approve-background: #06811233;
  --approve-colour: #068112;
  --verified-green: #69c737;
  --reject-background: #ec202633;
  --reject-colour: #dd1b3d;
  --blue-dark-text: #082857;
  --blue-dark-2: #163352;
  --blue-dark-3: #0e2736;
  --label-blue-dark: #29345f;
  --black-shadow: #00000017;
  --black-shadow-2: #0000001a;
  --black-background: #e1e1e100;
  --background-blue-dark: #1f3658;
  --blue-dark-4: #093050;
  --blue-dark-4-background: rgb(9, 48, 80, 0.1);
  --blue-light-background: #f0f6ff;
  --grey-shade: #14374b;
  --dot-grey: #a5a5a5;
  --light-black: #0b2239;
  --light-border: #dedede;
  --grey-title: #353535;
  --grey-title-2: #484848;
  --grey-title-3: #8a8a8a;
  --grey-title-4: #bbb;
  --grey-border: #e9e9e9;
  --grey-border-1: #c6c6c6;
  --grey-border-2: #7d8a9e;
  --grey-border-3: #848484;
  --grey-border-4: #e5e5e5;
  --grey-border-5: #3a3e5187;
  --grey-border-6: #c8c8c8;
  --grey-border-7: #959595;
  --grey-border-rgb: rgb(233, 230, 230);
  --grey-background-rgb-1: rgba(0, 0, 0, 0.2);
  --grey-background-rgb-2: rgba(0, 0, 0, 0.5);
  --grey-background-rgb-3: rgba(0, 0, 0, 0.15);
  --grey-background-rgb-4: rgba(183, 183, 183, 0.9);
  --grey-background-rgb-5: rgba(0, 0, 0, 0);
  --grey-background-rgb-6: rgba(0, 0, 0, 0.7);
  --grey-background-rgb-7: rgba(163, 175, 183, 0.9);
  --grey-background-rgb-hover: rgba(204, 204, 204, 0.2);
  --dark-blue: #002244;
  --grey-scroll-thumb: #dbdbdb;
  --grey-scroll-track: #f1f1f1;
  --grey-btn-border: #d4d4d4;
  --grey-background: #f2f2f2;
  --grey-footer-border: #ccc;
  --grey-bg: #0828571c;
  --green-1: #1c7814;
  --green-1-background: rgba(28, 120, 20, 0.3);
  --light-blue-background: #8fbcff;
  --dark-blue-font: #104697;
  --cyan-background: #8ff3ff;
  --blue-font: #108497;
  --pink-background: #ff8f8f;
  --red-font: #a80a0a;
  --blue-main: #1c3350;
  --grey-secondary: #8c97a5;
  --red-hover: #ffe6e6;
  --red-secondary: #cb3443;
  --white-dashboard: #dbe3ed;
  --white-dashbaord-2: #c1d1e6;
}
